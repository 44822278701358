html, body {
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
  height: 100%;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

body {
}

.wrap {
  display: flex;
  width: 100vw;
  height: 70vw;
  flex-grow: 1;
  position: relative;
}

#modelTools {
  min-width: 200px;
  padding: 0 3vw 0 0;
  display: flex;
  justify-content: space-between;
  position: fixed;
  right: 0;
  bottom: 0;
  width: 16vw;
  height: 3vw;
  min-height: 40px;
  pointer-events: none;
}

#modelTools img {
  width: 2vw;
  min-width: 30px;
  cursor: pointer;
  pointer-events: all;
}

.dropzone {
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.placeholder {
  display: none;
  width: 100%;
  max-width: 500px;
  border-radius: 0.5em;
  background: #EEE;
  padding: 2em;
  text-align: center;
}

.placeholder p {
  font-size: 1.2rem;
  color: #999;
}

.viewer {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  position: absolute;
  z-index: 0;
}

.axes {
  width: 100px;
  height: 100px;
  margin: 20px;
  padding: 0px;
  position: absolute;
  left: 0px;
  bottom: 0px;
  z-index: 10;
  pointer-events: none;
}

/******************************************************************************
 * Header
 */

header {
  display: flex;
  background: #353535;
  padding: 0 2em;
  height: 4rem;
  line-height: 4rem;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.3);
  z-index: 1;

  -webkit-app-region: drag;
}

header h1,
header .item,
header .separator {
  color: #F5F5F5;
  font-weight: 300;
  line-height: 4rem;
  margin: 0;
}

header h1 {
  font-size: 1.4rem;
}

header h1 > a {
  color: inherit;
  font-size: inherit;
  text-decoration: inherit;
}

header .item {
  padding: 0 1em;
  font-size: 0.8rem;
  text-decoration: none;
  transition: background ease 0.2s;

  -webkit-app-region: no-drag;
}

header .item:hover {
  background: #444;
}

header button.item {
  height: 34px;
  line-height: 35px;
  padding: 0 1em;
  border: 0;
  background: #ffc107;
  color: #333;
  font-weight: 500;
  border-radius: 2px;
  cursor: pointer;
}

header button.item:hover {
  color: #000;
}

header .separator {
  margin: 0 0.2em;
  opacity: 0.2;
}

header h1 + .separator {
  margin-left: 1em;
}

.flex-grow {
  flex-grow: 1;
}

.gui-wrap {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  pointer-events: none;
}

.gui-wrap > .main {
  pointer-events: all;
  max-height: 50vw;
}

.gui-wrap .close-button {
  display: none;
}

.main .closed {
  /*display: none;*/
}

.dg li.gui-stats:not(.folder) {
  height: auto;
}

@media screen and (max-width: 700px) {
  header h1 {
    font-size: 1em;
  }

  .layout-md {
    display: none;
  }
}

/******************************************************************************
 * Upload Button
 *
 * https://tympanus.net/Tutorials/CustomFileInputs/
 */

.upload-btn {
  display: none;
  margin-top: 2em;
}

.upload-btn input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.upload-btn label {
  color: #353535;
  border: 0;
  border-radius: 3px;
  transition: ease 0.2s background;
  font-size: 1rem;
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  padding: 0.625rem 1.25rem;
}

.upload-btn label:hover {
  background: #DDD;
}

.upload-btn svg {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentColor;
  margin-top: -0.25em;
  margin-right: 0.25em;
}


/******************************************************************************
 * Validation report
 */

.report {
  padding: 2em;
  max-width: 860px;
}

.report h1 {
  margin-top: 0;
}

.report p,
.report ul {
  line-height: 1.5em;
}

.report-table {
  text-align: left;
  border-collapse: collapse;
  width: 100%;
}

.report-table thead tr {
  background: #404040;
  color: #FFF;
}

.report-table th,
.report-table td {
  padding: 0.5em 1em;
}

.report-table tr:nth-child(2n) {
  background: #F0F0F0;
}

/******************************************************************************
 * Validation toggle
 */

.report-toggle-wrap.hidden {
  display: none;
}

.report-toggle {
  display: none;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 20px;
  height: 30px;
  box-shadow: 0px 0px 5px 0 rgba(0, 0, 0, 0.25);
  background: #FFF;
  box-sizing: border-box;

  color: #f0f0f0;
  background: #000;
  border-left: 6px solid #000;
}

.report-toggle.level-1 {
  color: #444;
  background: #ffeda0;
  border-left-color: #feb24c;
}

.report-toggle.level-0 {
  color: #444;
  background: #f4c2be;
  border-left-color: #b10026;
}

.report-toggle-text {
  line-height: 32px;
  padding: 0 0.5em;
  font-weight: 300;
  font-size: 0.8em;
}

.report-toggle-close {
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 1.5em;
  text-align: center;
}

/******************************************************************************
 * CSS Spinner
 *
 * http://tobiasahlin.com/spinkit/
 */

.spinner {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -20px;

  background-color: #333;

  border-radius: 100%;
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0)
  }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}

/******************************************************************************
 * Custom CSS
 *
 * author:yueming.liu
 */
.gui-wrap .dg li.title, .gui-wrap .dg .closed li.title {
  background-color: #4d4d4d !important;
  background-position: 250px 15px;
  padding-left: 18px !important;
}

.gui-wrap .dg li.folder {
  border-left: 0;
}

.gui-wrap .dg li:not(.folder) {
  background-color: rgba(0, 0, 0, 0.7);
  height: 36px;
  line-height: 36px;
  border-left: 0;
  padding-left: 12px;
}

.gui-wrap .dg .c input[type=checkbox] {
  /*display: none;*/
  /*visibility: hidden;*/
  position: absolute;
  right: -20px;
  top: 5px;
}

.gui-wrap .dg .c input[type=checkbox]::before {
  box-sizing: border-box;
  position: absolute;
  right: 20px;
  display: inline-block;
  content: "";
  width: 12px;
  height: 12px;
  border: 1px solid #FCB769;
  opacity: 1;
  border-radius: 2px;
  background-color: transparent;
}

.gui-wrap .dg .c input[type=checkbox]::after {
  box-sizing: border-box;
  position: absolute;
  right: 20px;
  display: inline-block;
  content: "";
  width: 12px;
  height: 12px;
  border: 1px solid #FCB769;
  opacity: 1;
  border-radius: 2px;
  background-color: transparent;
}

.gui-wrap .dg .c input[type=checkbox]:checked::after {
  box-sizing: border-box;
  position: absolute;
  right: 23px;
  top: 3px;
  /*left: 5px;*/
  display: inline-block;
  content: "";
  width: 6px;
  height: 6px;
  opacity: 1;
  border-radius: 1px;
  background-color: #FCB769;
}

.gui-wrap .dg li:not(.folder) {
  border-bottom: none;
}

.gui-wrap .dg .color .c input[type=text] {
  width: 80px;
  border-radius: 2px;
}

.gui-wrap .dg .c select {
  position: absolute;
  right: 10px;
  width: 100px;
  background-color: #666666;
  border-radius: 3px;
  outline: none;
  border: none;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  color: #D9D9D9;
  padding-left: 4px;
}

.gui-wrap .dg .c select option {
  height: 24px;
}

.gui-wrap .dg .slider {
  height: 4px;
  margin-top: 18px;
  margin-left: 0;
}

.gui-wrap .dg .c .slider-fg {
  background: #FCBC65;
}

.gui-wrap .dg .c .slider:hover .slider-fg {
  background: #FCBC65;
}

.gui-wrap .dg .cr.number input[type=text] {
  margin-top: 10px;
  color: #D9D9D9;
}
